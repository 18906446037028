.about-page {
padding: 80px 20px 80px 80px;
  
  .text-zone p {
    color: black;
  }
  

  
  
  .cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);
    
    div {
      position: absolute;
      width: 200px;
      height: 200px;
      border: 1px solid #ccc;
      background: rgba(255, 255, 255, 0.4);
      text-align: center;
      font-size: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 20px 0px lightyellow;
    }
    
    .face1 {
      transform: translateZ(100px);
      color: #ddc700;
    }
    .face2 {
      transform: rotateY(90deg) translateZ(100px);
      color: #f06529;
    }
    .face3 {
      transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
      color: #28a4d9;
    }
    .face4 {
      transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
      color: #5ed4f4;
    }
    .face5 {
      transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
      color: #efd81d;
    }
    .face6 {
      transform: rotateX(-90deg) translateZ(100px);
      color: #ec4d28;
    }
  }
  
  @keyframes spincube {
    from,
    to {
      transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    16% {
      transform: rotateY(-90deg);
    }
    33% {
      transform: rotateY(-90deg) rotateZ(90deg);
    }
    50% {
      transform: rotateY(-180deg) rotateZ(90deg);
    }
    66% {
      transform: rotateY(-270deg) rotateX(90deg);
    }
    83% {
      transform: rotateX(90deg);
    }
  }
  
  @media (max-width: 750px) {
    
    .stage-cube-cont {
      padding-top: 1px;
      margin-left: 110px;
      margin-right: 110px;
      padding-right: 0;
      height: 10%;
      width: auto;
    }
    h1 {
      color: rgb(255, 255, 255);
      margin: 0 auto;
  
      width: 300px;
      font-size: 45px;
      text-align: center;
      
    }
    
    p {
      font-size: 10px;
      color: #fff;
   
      text-align: center;
      font-family: sans-serif;
      font-weight: 300;
      font-size: 20px;
      min-width: 300px;


      animation: pulse 1s;
      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }
      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }
    
    
    .flat-button {
      color: white;
      font-size: 10px;
      font-weight: 400;
      letter-spacing: 4px;
      font-family: sans-serif;
      text-decoration: none;
      padding: 10px 18px;
      border: 1px solid #5835b8;
      margin-left: 10%;
      // float: left;
      animation: fadeInAnimation 1s 1.8s backwards;
      white-space: nowrap;
      margin-right: 20px;
      
      &:hover {
        background: #696574;
        color: white;
      }
    }
    
  }
  }