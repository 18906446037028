$primary-color: #ff0000;
@import 'animate.css';

@font-face {
  font-family: 'Helvetica Neue';
  src:url('./assets/fonts/helvetica-neu.ttf') format('tff');
}

@font-face {
  font-family: 'La Belle Aurore';
  src:url('./assets/fonts/LaBelleAurore.woff') format('woff2');
}

@font-face {
  font-family: 'Helvetica Neue';
  src:url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
}



input, 
textarea {
  font-family: 'Helvetica Neue';
}