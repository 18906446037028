.portfolio-page {
    // padding-left: 100px;
    // padding-right: 50px;
    // width: calc(100% - 150px);
    // position: initial;
    // height: 100%;
    // overflow: auto;
    padding-left: 80px;
    padding-right: 20px;
  
    h1.page-title {
      margin-left: 100px;
      margin: 0 auto;
      margin-top: 100px;
      margin-bottom: 100px;
      color: white;
      text-align: center;
    }
  
    @media (max-width: 850px) {
      h1.page-title {
        margin-bottom: 50px;
        text-align: center;
      }
  
      .image-box {
        min-width: 200px;
        margin-bottom: 20px;
        justify-content: center;
      }
    }
  
    .images-container {
      display: flex;
      justify-content: center;
      gap: 20px;
      flex-wrap: wrap;
      padding-bottom: 100px;
  
      @media (max-width: 850px) {
        display: block;
      }
    }
  
    .image-box {
      position: relative;
      flex: 1 1 20%;
      height: 400px;
      overflow: hidden;
      border-radius: 10px;
      max-width: calc(25% - 10px);
  
      @media (max-width: 850px) {
        max-width: 100%;
      }
  
      .portfolio-image {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
  
      .content {
        position: absolute;
        width: calc(100% - 40px);
        z-index: 3;
        padding: 10px 20px;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.2) 0,
          rgba(0, 0, 0, 1)
        );
        bottom: -70px;
      }
  
      .title {
        margin-bottom: 0;
        margin-top: 0;
        color: #fff;
        font-size: 24px;
        font-weight: 500;
        line-height: 24px;
      }
  
      .description {
        font-size: 14px;
        margin-bottom: 5px;
        color: #fff;
        font-weight: 700;
      }
  
      .cover {
        image-rendering: auto;
      }
  
      .btn {
        margin-top: 30px;
        margin-right: 30px;
        margin-bottom: 10px;
        padding: 0 23px;
        height: 40px;
        line-height: 34px;
        border: 2px solid #ffffff;
        border-radius: 4px;
        font-size: 14px;
        color: #fff;
        background: transparent;
        text-transform: uppercase;
        font-weight: 700;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        cursor: pointer;
      }
  
      .btn:hover {
        transform: translateY(-3px);
        background: #000000;
      }
  
      &:after {
        content: "";
        background: linear-gradient(180deg, #0066ff, #000);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        opacity: 0;
      }
  
      &:hover:after {
        opacity: 0.85;
      }
  
      &:hover .content {
        bottom: 0;
        background: transparent;
      }
    }
  }